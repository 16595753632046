<template>
  <div
    class="app-container apply__new"
    style="padding: 100px 0px 120px"
  >
    <h1 class="apply__tit font-bold text-3xl mt-5 mb-5">
      <span class="highlight">
        <span>📝 지원서 작성하기</span>
      </span>
    </h1>
    <SimpleFormKR />
  </div>
</template>
<script>
import SimpleFormKR from './components/simpleFormKR.vue';

export default {
  name: 'Apply',
  components: {
    SimpleFormKR,
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/apply.scss";
</style>

